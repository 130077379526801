var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Overzicht annuleringen")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel", attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "align-baseline": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "dialog",
                            attrs: {
                              "close-on-content-click": false,
                              lazy: "",
                              "nudge-right": "100",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-date",
                              attrs: {
                                slot: "activator",
                                placeholder: "DD-MM-YYYY",
                                label: "Datum",
                                clearable: ""
                              },
                              slot: "activator",
                              model: {
                                value: _vm.date.formattedDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.date, "formattedDate", $$v)
                                },
                                expression: "date.formattedDate"
                              }
                            }),
                            _c("v-date-picker", {
                              ref: "picker",
                              attrs: {
                                "first-day-of-week": "1",
                                locale: "nl-nl",
                                min: "1910-01-01",
                                color: "#837f16"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchStatistics()
                                }
                              },
                              model: {
                                value: _vm.date.selectedDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.date, "selectedDate", $$v)
                                },
                                expression: "date.selectedDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "300px"
                      }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("WeekStatisticsView", {
                      attrs: { items: _vm.types, columns: _vm.columns }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }